import { IconButton } from "@mui/material";
import { Fragment, useEffect, useState } from "react"
import { Constants } from "constants/Constants"
import { IControlProps } from "models/IControlProps"
import SignaturePad from "react-signature-canvas";
import SignatureCanvas from "react-signature-canvas";
import React from "react";
import { Delete as DeleteIcon } from '@mui/icons-material';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import { AppFunctions } from "helpers/AppFunctions";

type SignaturePadFieldProps = IControlProps & {
    form?: any,
    onCutIconChange?: (key: string) => void,
    onDeleteIconChange?: (key: string) => void
}
const SignatureField = (props: SignaturePadFieldProps) => {
    const [isRequired, setIsRequired] = useState<boolean>(props.isrequired ?? false);
    const [base64, setBase64] = React.useState<string | undefined>(props.value);
    useEffect(() => {
        setIsRequired(props.isrequired ?? false)
    }, [props.isrequired])    

    const padRef = React.useRef<SignatureCanvas>(null);

    const clear = () => {
        padRef.current?.clear();
        setBase64(undefined);
        props.form.setValue(props.controlkey, null, { shouldDirty: props.isrequired ? false: true });
        props.onDeleteIconChange!(props.controlkey);
    };
        
    const trim = () => {
        const url = padRef.current?.getTrimmedCanvas().toDataURL("image/png");
        if (url) {
            setBase64(url);
        }
    };

    return (
        <Fragment>
            <div className={props.type !== Constants.ControlTypeHidden ? `form-group` : ``}>
                {props.type !== Constants.ControlTypeHidden && <label htmlFor={props.controlkey}>
                    <span>{props.label}</span>
                    {isRequired &&
                        <i className={`required-icon`}></i>
                    }
                    {!props.readOnly && base64 && < IconButton aria-label="trim" >
                        <ContentCutIcon onClick={() => {
                            props.form.setValue(props.controlkey, base64, { shouldDirty: true });
                            props.onCutIconChange!(props.controlkey);
                        }} />
                    </IconButton>}
                    {!props.readOnly && < IconButton aria-label="remove" >
                        <DeleteIcon onClick={clear} />
                    </IconButton>}
                </label>}
              
                <div className="input-container">
                    <input
                        hidden
                        className="form-control"
                        id={props.controlkey}
                        type={props.type || Constants.ControlTypeText}
                        value={base64}
                        {...(props.controlregister ?? {})}
                    />
                    <div>
                        {AppFunctions.IsNullOrWhiteSpace(props.form?.watch(props.controlkey)) && <SignaturePad
                            canvasProps={{ className: "sigCanvas" }}
                            onEnd={trim}
                            {...(props.controlregister, { ref: padRef } ?? { })}
                        />}
                        {props.form?.watch(props.controlkey)
                            && <img className={"sigImage"}
                            src={props.form?.getValues(props.controlkey)} /> }
                    </div>
                    {
                        props.error ? (<span className="error">{props.error}</span>) : null
                    }
                </div>
            </div>
        </Fragment>)
}
export default SignatureField;