import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { Fragment, useEffect, useState } from 'react';
import { IControlProps } from 'models/IControlProps';
import { Constants } from 'constants/Constants';
import { Control, Controller } from 'react-hook-form';
import { Moment } from 'moment';
import { z } from 'zod';
import moment from 'moment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { IconButton, InputAdornment } from '@mui/material';
import { dayOfWeekFormatter } from 'helpers/ControlHelper';

export type IDateControlProps = IControlProps & {
    control: Control<any, any>,
    className?: string,
    labelStart: string,
    labelEnd: string;
    onChange: (value: [Moment | null, Moment | null]) => void;
}

export const momentSchema = z.custom<Moment>((data) => moment.isMoment(data) && data.isValid(), { message: "Invalid date" });

export const dateRangeSchema = z.tuple([momentSchema.nullable(), momentSchema.nullable()]);

const DateRangePickerField = (props: IDateControlProps) => {
    const [isRequired, setIsRequired] = useState<boolean>(props.isrequired ?? false);
    useEffect(() => {
        setIsRequired(props.isrequired ?? false);

    }, [props.isrequired])

    return (
        <Fragment>
            <div className={props.type !== Constants.ControlTypeHidden ? `form-group` : ``}>
                {props.type !== Constants.ControlTypeHidden && <label htmlFor={props.controlkey}>
                    <span>{props.label}</span>
                    {isRequired &&
                        <i className={`required-icon`}></i>
                    }
                </label>}
                <Controller
                    name={props.controlkey}
                    control={props.control}
                    render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterMoment} >
                            <DateRangePicker
                                readOnly={props.readOnly ?? false}
                                className={props.className}
                                value={field.value!}
                                onChange={(newValue: [Moment | null, Moment | null]) => {
                                    field.onChange(newValue!);
                                    props.onChange(newValue!);
                                }}
                                localeText={{ start: props.labelStart, end: props.labelEnd }}
                                dayOfWeekFormatter={dayOfWeekFormatter}
                                slotProps={{
                                    textField: {
                                        required: isRequired,
                                        size: 'small',
                                        InputProps: {
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton>
                                                        <CalendarTodayIcon></CalendarTodayIcon>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }
                                    }
                                }}

                            />

                        </LocalizationProvider>
                    )}
                />

                {
                    props.error ? (<span className="error">{props.error}</span>) : ""
                }

            </div>
        </Fragment>
    )
}

export default DateRangePickerField;
