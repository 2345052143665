import { GridFooter, GridFooterContainer, GridPagination, useGridApiContext } from "@mui/x-data-grid-pro";
import IDataGridProps from "components/controls/DataGrid/models/IDataGridProps";
import { Pagination } from "components/controls/DataGrid/CustomPagination/Pagination";
import { GridFooterActionComponent } from "./GridFooterActionComponent";
import { Button } from "@mui/material";
import { DataGridFooterActionModel } from "../models/DataGridActionModel";

export const CustomFooterComponent = (props: IDataGridProps) => {
    const apiRef = useGridApiContext();
    if (!props.footerConfiguration?.enableCustomFooter) {
        return <GridFooter></GridFooter>;
    }

    const handleAdditionalButtonHandler = () => {
        const footerActionModel: DataGridFooterActionModel = {
            actionType: "",
            selectedRows: undefined
        }
        props.footerConfiguration?.onFooterActionClick?.(footerActionModel);
    }
    const isCheckboxEnable = apiRef.current.getAllColumns().map(x => x.field).findIndex(x => x === '__check__') >= 0;
    return (
        <GridFooterContainer>
            <div className="col-sm-12">
                <div className="row !import align-items-center">
                    <div className="col-xxl-3 col-xl-12">
                        {isCheckboxEnable &&
                            <span> {apiRef.current.state.rowSelection.length > 0 ? apiRef.current.state.rowSelection.length + ' Item(s) Selected' : 'No Items Selected'} </span>
                        }
                        {props.footerConfiguration.showAdditionalButton &&
                            <span className="ms-3">
                                <Button
                                    type="button"
                                    variant="text"
                                    className="txt-transform custom-button"
                                    disabled={!(apiRef.current.state.rowSelection.length > 0)}
                                    onClick={handleAdditionalButtonHandler}>{props.footerConfiguration?.footerActionButtonText}</Button>
                            </span>}
                    </div>
                    <div className="col-xxl-9 col-xl-12">
                        <GridPagination ActionsComponent={Pagination} {...props} />
                    </div>
                </div>
                {isCheckboxEnable &&
                    <GridFooterActionComponent {...props}></GridFooterActionComponent>
                }
            </div>
        </GridFooterContainer>
    );
}

export default CustomFooterComponent;