import { Fragment, useEffect, useState } from "react";
import { IdLabelLookup } from "models/IdLabel";
import { IControlProps } from "models/IControlProps";
import { ModeEnum } from "constants/ModeEnum";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import { AppFunctions } from "helpers/AppFunctions";

type Props = IControlProps & {
    selectAnOptionText?: string;
};

const DropdownField = (props: Props) => {
    const [mode, setMode] = useState<ModeEnum>();

    useEffect(() => {
        const value = props.form?.getValues(props.controlkey) ?? props.value;
        if (props.options?.length && props.options.length > 0 && value) {
            props.form?.setValue(props.controlkey, value)
        }
    }, [props.options])

    useEffect(() => {
        if (AppFunctions.IsNullOrUndefined(props?.mode)) {
            setMode(ModeEnum.Edit)
            return;
        }
        setMode(props.mode)

    }, [props.mode])

    return (
        <Fragment>
            <>
                {mode === ModeEnum.Inline &&
                    <div className={props.customInlineClass ? 'custom-inline-class' : ''}>
                        <div className="inline-readonly-container">
                            <span className="inline-readonly-label">{props?.label}</span>
                            <span className="inline-readonly-field"> {props.options?.find((x: { id: any; }) => x.id == props.form?.getValues(props.controlkey))?.label}</span>
                        </div>
                    </div>
                }
                {mode === ModeEnum.Preview &&
                    <div>
                        <label htmlFor={props.controlkey}>
                            <span className={props?.labelClass}>{props?.label}</span>
                        </label>
                        <div className={props?.fieldClass}>
                            <span>{props.options?.find((x: { id: any; }) => x.id == props.form?.getValues(props.controlkey))?.label}</span>
                        </div>
                    </div>
                }

                {mode === ModeEnum.Edit && <div className={`form-group ${props.inputStyle}`}>
                    {!props?.formViewMode && <label htmlFor={props.controlkey}>
                        <span className={props.error ? props?.labelClass + ' lbl-error' : props?.labelClass}>{props.label}</span>
                        {props.isrequired && (
                            <i className={`required-icon`}></i>
                        )}
                    </label>}
                    <div className='input-container background-grayout'>

                        <select
                            className={`${props.error ? props.customClass + ' has-error' : props.customClass} select-input-field`}
                            disabled={props.readOnly}
                            id={props.controlkey}
                            value={props.value}
                            key={props.controlkey}
                            {...(props.controlregister ?? {})}
                        >
                            {(props.showSelectOption ?? true) && <option value=''>{props.selectAnOptionText ?? `Select an option`}</option>}
                            {(props.options?.length! > 0) && props.options?.map((option: IdLabelLookup) => (<option key={option.id} value={option.id}>{option.label}</option>))}
                            {props?.formViewMode && (<option>{props.label} {props.isrequired ? "*" : ""} </option>)}
                        </select>
                        {
                            props.error ? (<span className="error">{props.error}</span>) : null
                        }
                    </div>
                </div>}


                {mode === ModeEnum.Add && <div className="mb-3">

                    <FormControl sx={{ width: "100%" }} className="custom-select-dropdown">
                        <InputLabel id={"select-filed" + props.label} >{props.label}{props.isrequired && <i className={`required-icon`}></i>}</InputLabel>
                        <Controller
                            name={props.controlkey}
                            control={props.form.control}
                            defaultValue={[]}
                            render={({ field }) => (
                                <Select
                                    size="small"
                                    label={props.label}
                                    labelId={"select-filed" + props.label}
                                    required={props.isrequired}
                                    readOnly={props.readOnly}
                                    disabled={props.readOnly}
                                    defaultValue={props.form.getValues(props.controlkey)}
                                    key={props.controlkey}
                                    id={props.controlkey}
                                    {...(props.controlregister ?? {})}
                                    {...field}
                                    MenuProps={{ PaperProps: { className: "select-menu-box" } }}

                                >
                                    {props.options?.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </FormControl>

                    {
                        props.error ? (<span className="error">{props.error}</span>) : null
                    }
                </div>}
            </>
        </Fragment>
    )
};

export default DropdownField;