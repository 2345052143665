import { Chip, Stack, Tooltip } from "@mui/material";
import { gridFilterModelSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid-pro";
import { AppFunctions } from "helpers/AppFunctions";
import { IdLabelActiveCodeLookup } from "models/IdLabel";

type Props = {
    tag: IdLabelActiveCodeLookup,
    onClearAll: () => void
}
export const FilterTagOption = ({ tag, onClearAll }: Props): JSX.Element => {
    const apiRef = useGridApiContext();
    const gridFilterModel = useGridSelector(apiRef, gridFilterModelSelector);

    const filterItem = gridFilterModel.items.find(t => t.field == tag.code)
    if ((filterItem?.id === undefined && filterItem?.value === undefined)
        || (typeof (filterItem?.id) === 'number' && filterItem?.value === undefined))
        return <></>;

    const filterItemValue = !AppFunctions.IsNullOrWhiteSpace(filterItem?.id as string) && typeof (filterItem?.id) !== 'number'
        ? JSON.parse(filterItem?.id as string) : filterItem?.value;
    const itemValue = (filterItemValue.map((x: any) => {
        if (AppFunctions.IsNullOrWhiteSpace(x)) x = '(Blank)';
        return x;
    })) as string[]

    const filterItemValues = itemValue;

    const handleClick = () => {
    };

    const handleDelete = () => {
        apiRef.current.deleteFilterItem(filterItem!)
        onClearAll();
    };

    const tagLabel = filterItemValues.length === 1 ? filterItemValues[0] : `${filterItemValues[0]}  +${filterItemValues.length - 1}`;
    const toolTipLabel = filterItemValues.join(', ');

    return (<>
        <Stack direction="row" paddingLeft={1} spacing={1}>
            <Tooltip title={toolTipLabel}>
                <Chip label={tagLabel} sx={{ height: 23 }}
                    onClick={handleClick}
                    onDelete={handleDelete}
                />
            </Tooltip>
        </Stack>
    </>)
}

export default FilterTagOption;