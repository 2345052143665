import { getGridDateOperators, getGridBooleanOperators, getGridNumericOperators, getGridStringOperators, GridColDef, GridFilterItem, GridFilterOperator, getGridSingleSelectOperators, GridCellParams } from "@mui/x-data-grid-pro";

export const GenericOperators = (gridColDef: GridColDef): GridFilterOperator[] | undefined => {

    let operators: GridFilterOperator[] | undefined;

    switch (gridColDef.type) {
        case 'string':
            operators = getGridStringOperators().filter(
                (operator) => operator.value !== 'isAnyOf'
            );
            break;
        case 'date':
        case 'dateTime':
            operators = getGridDateOperators()
            break;
        case 'boolean':
            operators = getGridBooleanOperators()
            break;
        case 'amount':
        case 'number':
            operators = getGridNumericOperators().filter(
                (operator) => operator.value !== 'isAnyOf'
            )
            break;
        case 'singleSelect':
            operators = getGridSingleSelectOperators();
            break;
        default:
            operators = gridColDef.filterOperators
            break;
    }

    return [
        {
            label: 'IN',
            value: 'in',
            getApplyFilterFn: (filterItem: GridFilterItem) => {
                if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                    return null;
                }

                return (params: GridCellParams): boolean => {
                    switch (gridColDef.type!) {
                        case 'custom': {
                            let isOk = true
                            filterItem?.value?.forEach((fv: any) => {
                                isOk = isOk && (params.value as any[]).includes(fv)
                            })
                            return isOk
                        }
                        default:
                            const value = ['boolean', 'number'].includes(gridColDef.type!) ?
                                params.value : (params.value === null ? '' : params.value?.toString());
                            return filterItem.value?.includes(value)
                    }
                };
            },
            getValueAsString: (value: string[]) => JSON.stringify(value),
            requiresFilterValue: false
        },
        ...operators ?? []
    ];
}
