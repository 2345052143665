import { QueryBuilderService } from "common/services/QueryBuilderService";
import { FileExtensionEnum } from "constants/FileExtensionEnum";
import { AppFunctions } from "helpers/AppFunctions";
import { DataGridUtility, GridColumnDefinition } from "../../components/controls/DataGrid/DataGridUtilty";
import { ExportCustomQueriesRequestModel } from "pages/list-query-builder/QueryBuilder";
import moment from "moment";
import { MappingDefinition } from "pages/project/import-export/Types";

export async function ExportCustomQueries(model: ExportCustomQueriesRequestModel) {
    await QueryBuilderService.getQueryResultsByIdsAsync(model).then((response) => {
        const ResultModelList = response;
        for (let resultModel of ResultModelList) {
            let exportData: GridColumnDefinition = {};
            let fileName: string = `${model.clientAlias} ${resultModel.name}`;
            let dateFormat: string = "";
            if (model.startDate) {
                dateFormat = `${moment(model.startDate)?.format("yyyyMMDD")}-${moment(model.endDate)?.format("MMDD")}`
                fileName = fileName + "-" + dateFormat;
            }
            let sortedResults: any;
            if (resultModel.result.length > 0) {
                let records = resultModel.result;
                const dataKeys = Object.keys(records[0]);
                const selectedColumns = resultModel.selectClause!.split(',');
                var excludedKeys = dataKeys.filter(x => selectedColumns.find(f => f.toUpperCase() === x.toUpperCase()) == null);

                excludedKeys.forEach(key => {
                    records = records.map(
                        ({ [key]: removedProperty, ...keepAttrs }) => keepAttrs
                    );
                });
                const uniqueColumns: MappingDefinition[] = [];
                const recordBeforeUpdate: any = records;
                resultModel.mappings!.filter(x => selectedColumns.find(f => f.toUpperCase() === x.hydrosoftData.toUpperCase()) != null)
                    .filter(x => !AppFunctions.IsNullOrWhiteSpace(x.hydrosoftDataLabel))
                    .forEach((x) => {
                        records = records.map((elm: any) => ({
                            [x.hydrosoftDataLabel!]: elm[x.hydrosoftData],
                            ...elm,
                        }));
                        records = records.map(
                            ({ [x.hydrosoftData]: removedProperty, ...keepAttrs }) => keepAttrs
                        );
                    });

                resultModel.mappings!.forEach(x => {
                    if (uniqueColumns.findIndex(o => o.field == x.hydrosoftData) < 0) {
                        uniqueColumns.push({ field: x.hydrosoftData, headerName: x.integrationField, hydrosoftData: x.hydrosoftData } as MappingDefinition)
                    }
                    else {
                        const fieldId = x.integrationField.replaceAll(' ', '').toLowerCase();
                        if (uniqueColumns.findIndex(o => o.field.toLowerCase() == fieldId) < 0) {
                            uniqueColumns.push({ field: fieldId, headerName: x.integrationField, hydrosoftData: x.hydrosoftData } as MappingDefinition)
                        }
                    }
                });
                if (recordBeforeUpdate.length > 0 && recordBeforeUpdate[0] != null && typeof recordBeforeUpdate[0] === "object" && Object.keys(recordBeforeUpdate[0]).length > 1) {
                    const notexistColumns = uniqueColumns.filter(x => !selectedColumns.includes(x.field) && !AppFunctions.IsNullOrUndefined(x.field));
                    records.map((obj: any, index:number) => {
                        notexistColumns.forEach(y => {
                            if (Object.keys(obj).findIndex(o => o == y.field) < 0) {
                                obj[y.headerName] = recordBeforeUpdate[index][y.hydrosoftData];
                            }
                        });
                        return obj;
                    });
                }

                sortedResults = records;
            }
            else {
                let result: any[] = [];
                for (let data of resultModel.mappings!) {
                    let displayName = data.fieldName;
                    exportData[displayName] = [data.hydrosoftDataLabel!, "string"];
                    result.push({ [displayName]: "" });
                }

                sortedResults = result;
            }


            exportFiles(sortedResults, fileName, model.fileType, exportData);
        }
    });
}

const exportFiles = (dataList: any, fileName: string, fileType: string, exportedColumns: GridColumnDefinition) => {
    switch (fileType) {
        case FileExtensionEnum.CsvFile:
            DataGridUtility.exportToFile(dataList, fileName, FileExtensionEnum.CsvFile, exportedColumns);
            break;
        case FileExtensionEnum.XlsxFile:
            DataGridUtility.exportToFile(dataList, fileName, FileExtensionEnum.XlsxFile, exportedColumns);
            break;
        case FileExtensionEnum.CsvWithQuotesFile:
            DataGridUtility.exportToFile(dataList, fileName, FileExtensionEnum.CsvWithQuotesFile, exportedColumns);
            break;
        case FileExtensionEnum.TxtFile:
            DataGridUtility.exportToFile(dataList, fileName, FileExtensionEnum.TxtFile, exportedColumns);
            break;
        default:
            DataGridUtility.exportToFile(dataList, fileName, FileExtensionEnum.CsvFile, exportedColumns);
            break;
    }
}