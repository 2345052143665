import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { NavLink, useParams } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SideBarActive } from "store/SideBarStore";
import { RootState } from "store/store";
import { hasModulePermission } from "helpers/PermissionHelper";
import { useEffect, useState } from "react";
import { ProjectService } from "common/services/client/ProjectService";
import { ProjectTypeCategoryEnum } from "constants/ProjectOptions";
import { NormalizedRoleEnum } from "constants/NormalizedRoleEnum";
import storage from "common/utils/storage";
import DeviceIcon from "common/icons/DeviceIcon";
import React from "react";

export type RefType = {
    setIsTestingCategoryStatus: (isTestingCategoryStatus: boolean) => void;
    onProjectUpdateStatus: (isSubContractorChange: boolean) => void;
}

const ProjectSidebar = React.forwardRef<RefType, {}>((props, ref): JSX.Element => {
    const dispatch = useDispatch();
    const [isTestingCategory, setIsTestingCategory] = useState<boolean>(false);
    const [isSubContractorEnabled, setIsSubContractorEnabled] = useState<boolean>(false);
    const active = useSelector((state: RootState) => state.sidebar) as {
        isActiveState: boolean;
    };
    const userSession = storage.getUserSession();
    const hasAdminPermission: boolean = userSession.normalizedRoleName === NormalizedRoleEnum.SuperAdmin;
    const params = useParams();
    const projectRouteInitialPath = `/project/${params.projectId}/${params.clientId}`;
    const permissions = useSelector((state: RootState) => state.permissions.rolePermissions);
    const lookup = useSelector((state: RootState) => state.lookupDetails.typeList);
    useEffect(() => {
        (async () => await setData())();
    }, [params.projectId])

    React.useImperativeHandle(ref, () => ({
        setIsTestingCategoryStatus: (isTestingCategoryStatus: boolean) => {
            if (isTestingCategoryStatus !== isTestingCategory) {
                setIsTestingCategory(isTestingCategoryStatus)
            }
        },
        onProjectUpdateStatus: async (isSubContractorChange: boolean) => {
            setIsSubContractorEnabled(isSubContractorChange);
        }
    }));

    const setData = async () => {
        await ProjectService.getProjectDetailByIdAsync(params.projectId!).then((projectData) => {
            const selectedCategoryCode = lookup?.find(x => x.id == projectData.projectInfo.projectTypeId)?.categoryCode!;
            setIsTestingCategory(selectedCategoryCode === ProjectTypeCategoryEnum.Testing);
            setIsSubContractorEnabled(projectData.projectInfo?.isSubContractor!);
        });
    }

    const projectSidebarData = [
        {
            path: `${projectRouteInitialPath}/dashboard`,
            navIcon: <BarChartOutlinedIcon />,
            label: "Dashboard",
        },
        ...(hasModulePermission(permissions, "Projects") ? [{
            path: `${projectRouteInitialPath}/details`,
            navIcon: <ArticleOutlinedIcon />,
            label: "Project Details",
        }] : []),
        ...(hasAdminPermission ? [{
            path: `${projectRouteInitialPath}/import-export`,
            navIcon: <svg
                viewBox="0 0 24 24"
                role="presentation"
                style={{ width: "1.5rem", height: "1.5rem" }}
            >
                <path
                    d="M8.84 12L3.92 16.92L2.5 15.5L5 13H0V11H5L2.5 8.5L3.92 7.08L8.84 12M12 3C8.59 3 5.68 4.07 4.53 5.57L5 6L6.03 7.07C6 7.05 6 7 6 7C6 6.5 8.13 5 12 5S18 6.5 18 7 15.87 9 12 9C9.38 9 7.58 8.31 6.68 7.72L9.8 10.84C10.5 10.94 11.24 11 12 11C14.39 11 16.53 10.47 18 9.64V12.45C16.7 13.4 14.42 14 12 14C11.04 14 10.1 13.9 9.24 13.73L7.59 15.37C8.91 15.77 10.41 16 12 16C14.28 16 16.39 15.55 18 14.77V17C18 17.5 15.87 19 12 19S6 17.5 6 17V16.96L5 18L4.54 18.43C5.69 19.93 8.6 21 12 21C16.41 21 20 19.21 20 17V7C20 4.79 16.42 3 12 3Z"
                    style={{ fill: "currentcolor" }}
                ></path>
            </svg>,
            label: "Import/Export",
        }] : []),
        ...(hasModulePermission(permissions, "Facilities") ? [{
            path: `${projectRouteInitialPath}/facility`,
            navIcon: <DomainOutlinedIcon />,
            label: "Facilities",
        }] : []),
        ...(hasModulePermission(permissions, "Devices") && isTestingCategory ? [{
            path: `${projectRouteInitialPath}/devices`,
            navIcon: <DeviceIcon />,
            label: "Devices",
        }] : []),
        ...(hasModulePermission(permissions, 'Testing') && isTestingCategory  ? [{
            path: `${projectRouteInitialPath}/tests`,
            navIcon: <ListAltRoundedIcon />,
            label: "Tests",
        }] : []),
        ...(hasModulePermission(permissions, "Notices") ? [{
            path: `${projectRouteInitialPath}/notices`,
            navIcon: <EmailOutlinedIcon />,
            label: "Notices",
        }] : []),
        ...(hasModulePermission(permissions, 'Work Item') ? [{
            path: `${projectRouteInitialPath}/work-items`,
            navIcon: <AssignmentTurnedInOutlinedIcon />,
            label: "Work Items",
        }] : []),
        ...(hasModulePermission(permissions, 'Projects') ? [{
            path: `${projectRouteInitialPath}/team-members`,
            navIcon: <PermIdentityOutlinedIcon />,
            label: "Team Members",
        }] : []),
        ...(hasModulePermission(permissions, "Testing") && isTestingCategory && isSubContractorEnabled ? [{
            path: `${projectRouteInitialPath}/sub-contractor`,
            navIcon: <PermIdentityOutlinedIcon />,
            label: "Subcontractors",
        }] : [])
    ];

    const index = projectSidebarData.findIndex(x => x.label === "Facilities");
    if (index !== -1) {
        projectSidebarData.splice(index + 1, 0, ...(hasModulePermission(permissions, "Project Schedule") && !isTestingCategory ? [{
            path: `${projectRouteInitialPath}/schedule/`,
            navIcon: <CalendarMonthOutlinedIcon />,
            label: "Project Schedule",
        }] : []),
            ...(hasModulePermission(permissions, "Work Assignments") && !isTestingCategory ? [{
                path: `${projectRouteInitialPath}/work-assignments`,
                navIcon: <PermContactCalendarOutlinedIcon />,
                label: "Work Assignments",
            }] : []))
    }

    return (
        <div className={`custom-sidebar ${active.isActiveState ? "active" : ""}`}>
            <IconButton
                onClick={() => {
                    dispatch(SideBarActive(!active.isActiveState));
                }}
                className="toggle-button"
            >
                <ChevronRightOutlinedIcon />
            </IconButton>

            <ul>
                {projectSidebarData.map((item) => (
                    <li key={item.label}>
                        <NavLink to={item.path}>
                            <Tooltip title={item.label} arrow placement="right-start">
                                <span className="tab-icon">{item.navIcon}</span>
                            </Tooltip>
                            {active.isActiveState && <label> {item.label}</label>}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
});

export default ProjectSidebar;
