import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { AuthStoreSlice } from "./AuthStore";
import { PermissionSlice } from "./Permissions";
import { UserStoreSlice } from "./UserStore";
import { LookupStoreSlice } from "./LookupStore";
import { AppointmentStoreSlice } from "./AppointmentStore";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from 'redux-persist/lib/storage/session'
import { SideBarSlice } from "./SideBarStore";
import { CustomTabValueStoreSlice } from "./CustomTabValueStore";
import { ProjectStoreSlice } from "./ProjectStore";
import { PaymentStoreSlice } from "./PaymentStore";

const persistConfig = {
    key: "root",
    storage: storageSession
};

const allReducers = combineReducers({
    login: AuthStoreSlice.reducer,
    users: UserStoreSlice.reducer,
    permissions: PermissionSlice.reducer,
    lookupDetails: LookupStoreSlice.reducer,
    appointmentInformation: AppointmentStoreSlice.reducer,
    sidebar: SideBarSlice.reducer,
    customTabValue: CustomTabValueStoreSlice.reducer,
    projectInformation: ProjectStoreSlice.reducer,
    paymentInformation: PaymentStoreSlice.reducer

})

const persistedReducer = persistReducer(persistConfig, allReducers);

/*https://stackoverflow.com/questions/75715252/a-non-serializable-value-was-detected-in-the-state-in-the-path-filters-startd*/
//const defaultMiddlewareConfig = {
//    serializableCheck: {
//        ignoredPaths: ["filters.startDate", "filters.endDate"],
//    }
//};

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

