import { IdLabelAvatarEmailLookup, IdLabelLookup } from "../../../../models/IdLabel"
import { SearchableDropdownConfigurations } from "../../SearchableDropdown"

export type DataGridActionModel = {
    id: string,
    label: string,
    isDependend: boolean,
    controlType?: string,
    buttonText?: string,
    placeHolderText?: string,
    defaultOptions?: IdLabelAvatarEmailLookup[] | IdLabelLookup[],
    hideAvatarName?:boolean;
}
export type FooterConfiguration = {
    enableCustomFooter?: boolean;
    showFooterAction?: boolean;
    enableCheckboxSelection?: boolean;
    disableAdditionalFooterAction?: boolean;
    showCancelButton?: boolean | true;
    showAdditionalButton?:  boolean | false;
    footerActionButtonText?: string;
    footerActionDropdownLabel?: string;
    footerActionOptions?: DataGridActionModel[],
    dataUrl?: string,
    objectId?:string,
    onFooterActionClick?: (data: DataGridFooterActionModel) => void,
    onGridRowSelectionChange?:(data: DataGridFooterActionModel) => boolean
}

export type DataGridFooterActionModel = {
    actionType: string,
    selectedRows: any,
    selectedObject?: IdLabelAvatarEmailLookup,
    selectedValue?: boolean
}

export const SelectedValueDropdownConfiguration: SearchableDropdownConfigurations = {
    isArrowIconShow: false,
    isRecentLabelShow: false,
    isSearchButtonShow: false,
    isSearchBoxVisible: true,
    isSearchBoxLarge: false,
    isSelectedItemBorderShow: false,
    isRequired: true,
}