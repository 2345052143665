export class Constants {
    public static get ControlTypeHidden(): string { return "hidden"; }
    public static get ControlTypePhone(): string { return "phone"; }
    public static get ControlTypeText(): string { return "text"; }
    public static get ControlTypePassword(): string { return "password"; }
    public static get ControlTypeEmail(): string { return "email"; }
    public static get ControlTypeUrl(): string { return "url"; }
    public static get ControlTypeDate(): string { return "date"; }
    public static get ControlTypeDateTime(): string { return "datetime-local"; }
    public static get ControlTypeCheckbox(): string { return "checkbox"; }
    public static get ControlTypeCurrency(): string { return "currency"; }
    public static get ControlTypePercentage(): string { return "percentage"; }
    public static get ControlTypeInteger(): string { return "integer"; }
    public static get ControlTypeDecimal(): string { return "number"; }
    public static get ControlTypeRadio(): string { return "radio"; }
    public static get ApprovalQueue(): string {  return "approvalQueue" }
    public static get WorkItemHistory(): string {  return "workItemHistory" }
    public static get WorkItemStatus(): string { return "workItemStatus" }
    public static get InstallationNotes(): string { return "installationNotes" }
    
    public static get RegexPhone(): RegExp { return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/; }
    public static get RegexEmail(): RegExp { return /^([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)?$/i; }
    public static get RegexPassword(): RegExp { return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,10}$/; }
    public static get RegexDigits(): RegExp { return /\d/g; }
    public static get RegexName(): RegExp { return /^[a-zA-Z ]*$/; }
    public static get RegexNumber(): RegExp { return /^[a-zA-Z]*[0-9]+[^a-zA-Z]*$/; }
    public static get RegexCapital(): RegExp { return /^[A-Z]+$/; }
    public static get TabKeyName(): string { return "Tab"; }
    public static get RegexPositiveNumbers(): RegExp { return /^(0|[1-9]\d*)$/; }
    public static get RegexPositiveNegativeNumbers(): RegExp { return /^-?(0|[1-9]\d*)$/; }
    public static get RegexZipCode(): RegExp { return /^\d{5}-\d{4}|\d{5}|[A-Z]\d[A-Z] \d[A-Z]\d$/; }
    public static get RegexLookupCode(): RegExp { return /^[A-Z0-9#_-]+(?:[#_-]{0,2}[A-Z0-9]+)*$/; }
    public static get RegexClientName(): RegExp { return /^([a-zA-Z0-9_. -])*$/; }
    public static get RegexPostalCode(): RegExp { return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/; }
    public static get RegexState(): RegExp { return /^([a-zA-Z ][a-zA-Z ])+$/;}
    public static get EnterKeyName(): string { return "Enter"; }
    public static get RegexAlphaNumericWithSpecialCharacters(): RegExp { return /^[ A-Za-z0-9_@./#&+-]*$/; }
    public static get RegexServiceContactName(): RegExp { return /^([a-zA-Z0-9-&,.\s])*$/; }
}