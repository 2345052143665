import { createSlice } from "@reduxjs/toolkit";
import { ProjectInformationModel } from "pages/client/projects/ProjectInformation";

export const ProjectStoreSlice = createSlice({
    name: "project",
    initialState: {} as ProjectInformationModel,
    reducers: {
        ProjectInformationStore: (state: ProjectInformationModel, action) => {
            state.id = action.payload.id;
            state.statusLabel = action.payload.statusLabel;
            state.projectName = action.payload.projectName;
            state.projectTypeId = action.payload.projectTypeId;
            state.isManualWorkItem = action.payload?.isManualWorkItem;
            state.isSubContractor = action.payload?.isSubContractor;
        }
    },
});

export const { ProjectInformationStore } = ProjectStoreSlice.actions;