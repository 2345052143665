import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";
import { IControlProps } from "models/IControlProps";
import React, { useEffect } from "react";
import { IdLabelCodeLookup } from "models/IdLabel";
import { AppFunctions } from "helpers/AppFunctions";
import { ClickAwayListener } from "@mui/material";

export type SearchableSelectFieldsProps = IControlProps & {
  listOptions: IdLabelCodeLookup[];
  defaultOption: IdLabelCodeLookup;
}

export default function SearchableSelectFields(props: SearchableSelectFieldsProps) {

  const { controlkey, form, listOptions = [] } = props;
  const [autocompleValue, setAutocompleValue] = React.useState<IdLabelCodeLookup>(props.defaultOption);

  let data = [
    props.defaultOption,
    ...listOptions.sort((a: any, b: any) => {
      if (a.code.toLowerCase() === b.code.toLowerCase()) {
        return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
      }
      return a.code.toLowerCase() > b.code.toLowerCase() ? 1 : -1;
    }),
  ];

  useEffect(() => {
    if (!AppFunctions.IsNullOrWhiteSpace(props.value)) {
      let val = data.filter((obj: any) => obj.id === props.value);
      setAutocompleValue(val[0]);
      return;
    }
    setAutocompleValue(props.defaultOption);
  }, [props.value])


  const handleClcikOutside = () => {
    if (props.value && AppFunctions.IsNullOrUndefined(autocompleValue)) {
      let val = data.filter((obj: any) => obj.id === props.value);
      setAutocompleValue(val[0]);
    }
    if (AppFunctions.IsNullOrWhiteSpace(props.value) && AppFunctions.IsNullOrUndefined(autocompleValue)) {
      setAutocompleValue(props.defaultOption);
    }
  }

  return (
    <Controller
      name={controlkey}
      control={form.control}
      {...props}
      render={({ field }) => (
        <ClickAwayListener onClickAway={(event) => handleClcikOutside()}>
          <Autocomplete
            {...field}
            className="dropdown-search-inputbox"
            disablePortal
            groupBy={(option: any) => option.code}
            getOptionLabel={(option) => option.label}
            options={data}
            ListboxProps={{ style: { maxHeight: 180 }, className: "custom-scrollbar" }}
            value={autocompleValue}
            onChange={(_, value) => {
              if (value?.id) {
                field.onChange(value?.id);
              }
              setAutocompleValue(value!);
            }}
            renderInput={(params) => {
              const { InputProps, ...rest } = params;
              return (
                <TextField
                  {...rest}
                  InputProps={{
                    ...InputProps,
                  }}
                  placeholder="Search"
                />
              );
            }}
          />
        </ClickAwayListener>
      )}
    />
  );
}
