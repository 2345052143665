import { ProtectedRoute, Suspense, lazy, Loader } from "pages/RouteIndex";
const LazyCustomerArrivalNoteComponent = lazy(() => import('./CustomerArrivalNote'));
const LazyScheduleAppointmentComponent = lazy(() => import('./ScheduleAppointment'));

export const scheduleAppointmentRoutes = [
    {
        path: "schedule-appointment",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyScheduleAppointmentComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },

    {
        path: "customer-arrival-note",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyCustomerArrivalNoteComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
]