import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import SearchIcon from '@mui/icons-material/Search';
import { debounce, IconButton, InputAdornment, TextField } from '@mui/material';
import Autocomplete, {
    autocompleteClasses, AutocompleteCloseReason
} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { styled, useTheme } from '@mui/material/styles';
import {
    gridFilteredSortedRowEntriesSelector,
    GridFilterModel, gridFilterModelSelector,
    GridLogicOperator, GridValueGetterParams,
    useGridApiContext, useGridSelector
} from "@mui/x-data-grid-pro";
import { deepClone } from '@mui/x-data-grid/utils/utils';
import { cloneDeep } from 'lodash';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { AppFunctions } from 'helpers/AppFunctions';
import { GUID } from 'helpers/GuidFunction';
import { IdLabelActiveCodeLookup } from 'models/IdLabel';
import { CustomOperatorsEnum } from 'components/controls/DataGrid/Enums/FilterOperatorEnums';
import DataGridServiceType from 'components/controls/DataGrid/models/DataGridServiceType';
import { toComputePayloadForFilterTag } from 'components/controls/DataGrid/models/IDataGridRequestPayload';
import { FilterTagOption } from './FilterTagOption';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { Tooltip } from "@mui/material";
import { Loader } from 'components/Index';
import { ExtendedFieldMappingModel } from '../models/CellValueTransformation';

type PopperComponentProps = {
    anchorEl?: any;
    disablePortal?: boolean;
    open: boolean;
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13,
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
        padding: 0,
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 8,
            borderBottom: `1px solid  ${theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
                }`,
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
            {
                backgroundColor: theme.palette.action.hover,
            },
        },
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
    },
}));

function PopperComponent(props: PopperComponentProps) {
    const { disablePortal, anchorEl, open, ...other } = props;
    return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
    border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
    boxShadow: `0 8px 24px ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
        }`,
    borderRadius: 6,
    width: 300,
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const StyledInput = styled(TextField)(({ theme }) => ({
    padding: 10,
    width: '100%',
    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
        }`,
    '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontSize: 14
    },
}));

type FilterTagPopoverProps = {
    tag: IdLabelActiveCodeLookup,
    selectedTag?: IdLabelActiveCodeLookup | null,
    dataGridServiceType?: DataGridServiceType,
    showIconOption?: boolean,
    showParentDescription?: boolean,
    cellFormattedLookups?: ExtendedFieldMappingModel[]
}

export type FilterTagPopoverRefType = {
    handleClick: () => void
    handleClose: () => void
}

export const FilterTagPopover = React.forwardRef<FilterTagPopoverRefType, FilterTagPopoverProps>(({ tag, selectedTag, dataGridServiceType, showIconOption, showParentDescription, cellFormattedLookups }, ref): JSX.Element => {
    const apiRef = useGridApiContext();
    const gridFilterModel = useGridSelector(apiRef, gridFilterModelSelector);
    const filterItemValue = gridFilterModel.items.find(t => t.field == tag.code)?.value;
    const filterItemValues = filterItemValue !== undefined ? (filterItemValue) as string[] : [];
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [value, setValue] = React.useState<LabelType[]>(filterItemValues.map(function (x: any) { return { name: x, id: x } as LabelType }) ?? []);
    const [pendingValue, setPendingValue] = React.useState<LabelType[]>([]);
    const [options, setOptions] = React.useState<LabelType[]>([]);
    const [total, setTotal] = React.useState<number>(0);
    const [firstRowToRender, setFirstRowToRender] = React.useState<number>(0);
    const [lastRowToRender] = React.useState<number>(100);
    const [loading, setloading] = React.useState<boolean>(false);
    const theme = useTheme();
    const params = useParams();
    const filterTagId = params.facilityId ?? params.companyId ?? params.projectId;
    const objectId = !AppFunctions.IsNullOrWhiteSpace(filterTagId) ? filterTagId : null;
    const [inputValue, setInputValue] = React.useState("");

    React.useImperativeHandle(ref, () => ({
        handleClose: () => handleClose,
        handleClick: () => handleClick
    }));

    const gridFilteredSortedRowEntries = useGridSelector(apiRef, gridFilteredSortedRowEntriesSelector);

    let list: any[] = [];
    let dataList: LabelType[] = [];
    const debouncedOnChange = React.useCallback(debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        if (dataGridServiceType == null) return;
        const searchText = event.target.value;
        setFirstRowToRender(0);
        for (let i = 1; i <= gridFilteredSortedRowEntries.length; i++)
            gridFilteredSortedRowEntries.pop();

        list = [];
        dataList = [];
        setOptions([]);
        loadMore(searchText);
    }, 500), []);

    const loadMore = (searchText?: string | null) => {
        setloading(true);
        const filterModel = deepClone(gridFilterModel);
        filterModel.items = gridFilterModel.items.filter(x => x.field !== tag.code);
        const payload = toComputePayloadForFilterTag(tag.code, firstRowToRender, lastRowToRender, searchText, filterModel, objectId);
        Promise.resolve(dataGridServiceType?.getRows(dataGridServiceType.dataUrl, payload))
            .then((data) => {
                setFirstRowToRender(pre => (pre + lastRowToRender));
                data.returnedRows.forEach((x: any) => {
                    const record: any = { [tag.code]: x, value: x };
                    gridFilteredSortedRowEntries.push(record);
                    list = [...list, record];
                });
                setListValues(cloneDeep(list));
                getDataList();
                setloading(false);
            });
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        const initialIndex: number = 0;
        setFirstRowToRender(initialIndex);
        setOptions([]);
        const field = apiRef.current?.getAllColumns().find(x => x.field === tag.code);
        dataList = [];
        list = [];
        type DataGridCustomRowType = { [key: string]: string, value: any };
        const filterModel = deepClone(gridFilterModel);
        filterModel.items = gridFilterModel.items.filter(x => x.field !== tag.code);
        if (dataGridServiceType) {
            setloading(true);
            const payload = toComputePayloadForFilterTag(tag.code, initialIndex, lastRowToRender, null, filterModel, objectId);
            Promise.resolve(dataGridServiceType?.getRows(dataGridServiceType.dataUrl, payload))
                .then((data) => {
                    setTotal(data.totalRowCount);
                    setFirstRowToRender(lastRowToRender);
                    data.returnedRows.forEach((x: any) => {
                        let record = { [field!.field]: x, value: x } as DataGridCustomRowType;
                        list.push(record)
                    })
                    setListValues(cloneDeep(list));
                    getDataList();
                    setloading(false);
                })
        } else {

            const values = gridFilteredSortedRowEntries.map(x => x.model);
            setListValues(values);
            getDataList();
        }

        setAnchorEl(event.currentTarget);
    };
    function setListValues(sortedRows: any[]) {
        const field = apiRef.current?.getAllColumns().find(x => x.field === tag.code);
        if (field?.valueGetter || field?.valueFormatter) {
            list = [];
            sortedRows?.forEach(row => {
                let formattedValue: any;
                let actualValue: any;

                if (field.valueGetter) {
                    formattedValue = field.valueGetter!({ row: row } as GridValueGetterParams);
                    actualValue = formattedValue;
                }
                else {
                    try {
                        formattedValue = field.valueFormatter!({ value: row[field.field] } as any);
                        actualValue = row[field.field];
                    }
                    catch {
                        if (field.type === 'boolean') {
                            formattedValue = [true, 'TRUE', 'true'].includes(row[field.field] as any) ? 'Yes' : 'No';
                            actualValue = [true, 'TRUE', 'true'].includes(row[field.field] as any) ? true : false
                        }
                    }
                }
                if (Array.isArray(actualValue)) {
                    for (let i = 0; i < actualValue.length; i++) {
                        list.push({
                            id: GUID.NewGUID(),
                            actualValue: actualValue[i] ?? '',
                            value: formattedValue[i] ?? ''
                        })
                    }
                } else
                    list.push({
                        id: GUID.NewGUID(),
                        actualValue: actualValue ?? '',
                        value: formattedValue ?? ''
                    })
            })
        }
        else {
            list = sortedRows?.map(function (field) {

                return {
                    id: GUID.NewGUID(),
                    actualValue: field[tag.code] ?? '',
                    value: field[tag.code] ?? ''
                }
            });
        }
    }

    function getDataList() {
        dataList = list.filter((n, i) => list.indexOf(n) === i)
            .map(function (x: any) {
                return {
                    id: x.id,
                    name: x.actualValue,
                    code: x.value,
                    color: '#000000',
                } as LabelType;
            });

        const result: LabelType[] = Object.values(
            dataList.reduce((acc, obj) => ({ ...acc, [obj.name]: obj }), {})
        );

        const selectedValue = result.filter(x => filterItemValues.includes(x.name));
        setOptions(pre => [...pre, ...result]);
        setValue(pre => [...pre, ...selectedValue]);
        setPendingValue(pre => [...pre, ...selectedValue]);
    }

    const handleClose = () => {
        setValue(pendingValue);
        setPendingValue([]);
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const id = open ? 'filter-label' : undefined;
    const buttonRef = React.useRef<HTMLButtonElement>(null);

    React.useEffect(() => {
        if (tag.id === selectedTag?.id && buttonRef.current) {
            buttonRef.current.click();
        }
    }, []);

    return (
        <React.Fragment>
            <button
                ref={buttonRef}
                aria-label="Add Tag"
                className="filter-tag"
                data-focuszone=""
                data-is-focusable="true"
                tabIndex={0}
                type="button"
                onClick={handleClick}
            > {tag.label} </button>
            <FilterTagOption tag={tag} onClearAll={handleClose}></FilterTagOption>
            <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
                <ClickAwayListener onClickAway={handleClose}>
                    <div>
                        {showParentDescription && <Box
                            sx={{
                                borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
                                padding: '8px 10px',
                                fontWeight: 600,
                            }}
                        >
                            Apply labels to this column
                        </Box>}
                        <Autocomplete
                            open
                            multiple
                            onClose={(
                                event: React.ChangeEvent<{}>,
                                reason: AutocompleteCloseReason,
                            ) => {
                                if (reason === 'escape') {
                                    handleClose();
                                }
                            }}
                            value={pendingValue}
                            inputValue={inputValue}
                            onInputChange={(event, value, reason) => {
                                if (event && event.type === 'blur') {
                                    setInputValue('');
                                } else if (reason !== 'reset') {
                                    setInputValue(value);
                                }
                            }}

                            onChange={(event, newValue, reason) => {
                                if (
                                    event.type === 'keydown' &&
                                    (event as React.KeyboardEvent).key === 'Backspace' &&
                                    reason === 'removeOption'
                                ) {
                                    return;
                                }
                                setPendingValue(newValue);

                                let item = gridFilterModel.items.find(x => x.field == tag.code)
                                if (newValue.length === 0 && item) {
                                    apiRef.current.deleteFilterItem(item)
                                    return;
                                }

                                let selectedTagOptionsValue = newValue.map(m => m.name);
                                if (cellFormattedLookups) {
                                    const formattedField = cellFormattedLookups.find(x => x.integrationField === tag.label && x.lookupMappings);
                                    if (formattedField?.lookupMappings && formattedField?.lookupMappings?.length > 0) {
                                        selectedTagOptionsValue = newValue.map(x => formattedField?.lookupMappings?.find(z => z.formattedValue === x.name)?.label!);
                                    }
                                }

                                const displayedValues = JSON.stringify(newValue.map(m => m.code));
                                if (item) {
                                    item.value = selectedTagOptionsValue;
                                    item.id = displayedValues
                                }
                                else {
                                    gridFilterModel.items.push({
                                        field: tag.code,
                                        id: displayedValues,
                                        value: selectedTagOptionsValue,
                                        operator: CustomOperatorsEnum.FilterTagIncludeOperator
                                    })
                                }
                                apiRef.current.setFilterModel({
                                    items: [...gridFilterModel.items],
                                    logicOperator: GridLogicOperator.And,
                                    quickFilterValues: gridFilterModel.quickFilterValues ?? [],
                                } as GridFilterModel)
                            }}
                            disableCloseOnSelect
                            PopperComponent={PopperComponent}
                            renderTags={() => null}
                            noOptionsText="No Results"
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Box
                                        component={DoneIcon}
                                        sx={{ width: 17, height: 17, mr: '5px', ml: '-2px' }}
                                        style={{
                                            visibility: selected ? 'visible' : 'hidden',
                                        }}
                                    />
                                    {showIconOption && <Box
                                        component="span"
                                        sx={{
                                            width: 14,
                                            height: 14,
                                            flexShrink: 0,
                                            borderRadius: '3px',
                                            mr: 1,
                                            mt: '2px',
                                        }}
                                        style={{ backgroundColor: option.color }}
                                    />}
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            '& span': {
                                                color:
                                                    theme.palette.mode === 'light' ? '#586069' : '#8b949e',
                                            },
                                        }}
                                    >
                                        {AppFunctions.IsNullOrWhiteSpace(option.code) ? '(Blank)' : option.code}
                                        <br />
                                        <span>{option.description}</span>
                                    </Box>
                                    <Box
                                        component={CloseIcon}
                                        sx={{ opacity: 0.6, width: 18, height: 18 }}
                                        style={{
                                            visibility: selected ? 'visible' : 'hidden',
                                        }}
                                    />
                                </li>
                            )}
                            options={options.sort((first, second) => {
                                // Display the selected labels first.
                                let firstIndex = value.indexOf(first);
                                firstIndex = firstIndex === -1 ? value.length + options.indexOf(first) : firstIndex;
                                let secondIndex = value.indexOf(second);
                                secondIndex = secondIndex === -1 ? value.length + options.indexOf(second) : secondIndex;
                                return firstIndex - secondIndex;
                            })}
                            getOptionLabel={(option) => option.code!}
                            renderInput={(params) => <StyledInput
                                ref={params.InputProps.ref}
                                {...params}
                                autoFocus={false}
                                placeholder={`Search`}
                                onChange={debouncedOnChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (<>
                                        <Loader isLoading={loading}>
                                            {(total > options.length) ? <InputAdornment position="end">
                                                <Tooltip title='load-more'>
                                                    <IconButton className="serach-btn-filter" disabled={loading} onClick={() => loadMore()}>
                                                        <RefreshOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment> : ''}
                                        </Loader>
                                    </>)
                                }}
                            />}
                        />
                    </div>
                </ClickAwayListener>
            </StyledPopper>
        </React.Fragment>
    );
})


type LabelType = {
    id?: string,
    code?: string,
    name: string;
    color: string;
    description?: string;
}

export default FilterTagPopover;