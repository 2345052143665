import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Loader from './Loader';
import { MoreHoriz } from '@mui/icons-material';
import { useState } from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    className?: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
type ModalType = {
    className?: string,
    modalUniqueClass?: string,
    isOpen: boolean,
    title?: string,
    subTitle?: string,
    headerIconButton?: boolean,
    children: React.ReactNode,
    onClose?: () => void,
    showCloseButton?: boolean;
    additionalButtons?: Array<{
        label: string,
        disabled?: boolean,
        className?: string,
        variant?: 'text' | 'outlined' | 'contained'
    } & React.ButtonHTMLAttributes<HTMLButtonElement>>,
    isLoading?: boolean,
    size?: 'sm' | 'lg' | 'md' | 'xl' | 'xs'
    iconButton?: Array<{
        label: string,
        disabled?: boolean,
        className?: string,
        variant?: 'text' | 'outlined' | 'contained'
    } & React.ButtonHTMLAttributes<HTMLButtonElement>>,
}

const Modal = (props: ModalType) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Loader isLoading={props.isLoading!}>
            <BootstrapDialog
                className={`custom-modal ${props.modalUniqueClass ?? ''}`}
                fullWidth={true}
                maxWidth={props.size ?? 'md'}
                aria-labelledby="customized-dialog-title"
                open={props.isOpen}>
                <BootstrapDialogTitle className="dialog-custom-title" id="customized-dialog-title" onClose={props.onClose!}>
                    {props.title} {props.headerIconButton &&
                        <IconButton
                            aria-label="more-horiz"
                            onClick={() => setIsOpen(true)}
                            sx={{
                                marginleft: 8
                            }}>
                            <MoreHoriz />
                        </IconButton>}
                    {isOpen && props.iconButton?.map((button) => {
                        return <Button key={button.label}
                            size='small'
                            disabled={button.disabled}
                            variant={button.variant ?? 'text'}
                            className={button.className}
                            onClick={button.onClick}>
                            {button.label}
                        </Button>
                    })}
                </BootstrapDialogTitle>
                <DialogContent className={props.className}>
                    {props?.subTitle}
                    {props.children}
                </DialogContent>
                <hr />
                <DialogActions>
                    {(props.showCloseButton ?? true) && <Button key='modal-close' className="modal-cancel-btn" autoFocus onClick={props.onClose}>
                        Cancel
                    </Button>}
                    {props.additionalButtons?.map((button) => {
                        return <Button key={button.label}
                            size="small"
                            disabled={button.disabled}
                            variant={button.variant ?? 'contained'}
                            className={`confirm-btn ${button.className}`}
                            onClick={button.onClick}>{button.label}</Button>
                    })}
                </DialogActions>
            </BootstrapDialog>
        </Loader>
    );
}

export default Modal;