import { gridFilteredSortedRowEntriesSelector, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarQuickFilter, useGridSelector } from "@mui/x-data-grid";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import IDataGridProps from "components/controls/DataGrid/models/IDataGridProps";
import { CustomExportButton, CustomGridColumnsButton, GridBulkActionButton, ResetConfiguration } from "./Index";
import { Button } from "@mui/material";
import { GUID } from "helpers/GuidFunction";
import FilterTag from "components/controls/DataGrid/CustomFilter/FilterTag";

export const CustomToolbar = (props: IDataGridProps): JSX.Element => {
    const apiRef = useGridApiContext();
    const gridFilteredSortedRowEntries = useGridSelector(apiRef, gridFilteredSortedRowEntriesSelector);

    return (
        <>
            <GridToolbarContainer>
                <div className="toolbar-header table-title-wrapper">
                    <div className="title-search-wrapper">
                        {(props.showGlobalFilter ?? true) && <GridToolbarQuickFilter variant='outlined' size='medium' debounceMs={500} />}
                    </div>
                    <div className="toolbar-header-rightbox">
                        <div className='toolbar-filter-tag-wrapper'>
                            {!props.disableColumnFilter && <FilterTag
                                filterTags={props.filterTags}
                                dataGridServiceType={props.dataGridServiceType}
                                gridFilterInitialState={props.gridFilterInitialState}
                                cellFormattedLookups={props.cellFormattedLookups}
                                ></FilterTag>}
                        </div>
                        <div className="toolbar-actions-wrapper">
                            {(!props.disabledResetConfiguration ?? true) && <ResetConfiguration></ResetConfiguration>}
                            <GridToolbarDensitySelector />
                            {props.toolbarCustomButtons?.map((button) => {
                                return <Button
                                    key={GUID.NewGUID()}
                                    className={`${button.className ? button.className : 'file-custom-btn'}`}
                                    size="small"
                                    disabled={button.disabled}
                                    variant={button.variant ?? 'outlined'}
                                    onClick={button.onClick}>{button?.label}
                                </Button>
                            })}
                            {(props.showBulkAction ?? false) && <GridBulkActionButton></GridBulkActionButton>}
                            {props.toolbarCustomAdditionalButtons?.map((button) => {
                                return (<Button
                                    className='datagrid-toolbar-button'
                                    hidden={button.hidden}>{button.icon}
                                </Button>)
                            })}
                            {(!props.disableColumnSelector ?? true) && <CustomGridColumnsButton datagridprops={props} />}
                            {(!props.disableToolbarExport ?? true) && <CustomExportButton datagridprops={props} />}
                        </div>
                    </div>
                </div>
            </GridToolbarContainer >
            {!props.hideTotalRowCount && <p className="row-count-value">{gridFilteredSortedRowEntries.length + " Total"}</p>}
        </>
    );
}

export default CustomToolbar;