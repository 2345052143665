export enum FacilityStageEnum {
    InProgress = "INPROGRESS",
    Todo = "TODO",
    Done = "DONE",
  }

  export enum ProjectStatusEnum {
    Active = "Active",
    Rejected = "Rejected",
    Pending = "Pending",
    Completed = "Completed", 
    Cancelled = "Cancelled",

  }

export enum WorkItemStageEnum {
    Initiated = "INITIATED",
    Todo = "TODO",
    UnAssigned = "UNASSIGNED",
    Assigned = "ASSIGNED",
    InReview = "INREVIEW",
    NonResponsive="NONRESPONSIVE",
    ReWorkNeeded="REWORKNEEDED",
    Completed = "COMPLETED", 
    NoShow = "NOSHOW",
    UnableToComplete = "UNABLETOCOMPLETE",
    UnableToAssign = "UNABLETOASSIGN",
    FinalNotice = "FINALNOTICE"
}

export enum ApprovalStageEnum {
  InReview = "INREVIEW",
  Approved = "APPROVED",
  Rejected = "REJECTED",
  Inreview = "IN REVIEW"
}


export enum FieldActivityKeyEnum {
  WorkItemStatus = "workItemStatus",
}